<template>
  <div>
    <loading-more :fetch-data="fetchData" />
  </div>
</template>

<script>
import LoadingMore from "./components/loading-more";
import { getStandardList } from "@/api/company";

const standardColumns = [
  {
    title: "归口单位",
    dataIndex: "belongUnit",
  },
  {
    title: "标准分类号（中国）",
    dataIndex: "chiType",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "起草单位",
    dataIndex: "draftCompany",
  },
  {
    title: "起草人",
    dataIndex: "draftPerson",
  },
  {
    title: "实施日期",
    dataIndex: "effectDate",
  },
  {
    title: "标签",
    dataIndex: "label",
  },
  {
    title: "主管部门",
    dataIndex: "manageUnit",
  },
  {
    title: "标准分类号（国际）",
    dataIndex: "naType",
  },
  {
    title: "标准名称",
    dataIndex: "name",
  },
  {
    title: "标准编号",
    dataIndex: "number",
  },
  {
    title: "执行单位",
    dataIndex: "performUnit",
  },
  {
    title: "相关产品",
    dataIndex: "product",
  },
  {
    title: "发布日期",
    dataIndex: "publicDate",
  },
  {
    title: "团队",
    dataIndex: "team",
  },
  {
    title: "标准类型",
    dataIndex: "type",
  },
];

export default {
  data() {
    return {
      standard: {
        columns: standardColumns,
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          governAdvisor: "",
        },
      },
      ui: {},
    };
  },
  components: {
    LoadingMore,
  },
  methods: {
    async fetchData(companyId, page) {
      return (await getStandardList({ companyId, page: page, pageSize:10 }))
        .data;
    },
  },
};
</script>

<style lang="less" scoped>
</style>