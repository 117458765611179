<template>
  <div>
    <a-affix :offset-top="top">
      <a-tag color="green"> 总数：{{ total }} </a-tag>
    </a-affix>
    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="data"
    >
      <a-back-top />
      <div
        v-if="showLoadingMore"
        slot="loadMore"
        :style="{
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px',
        }"
      >
        <a-spin v-if="loadingMore" />
        <a-button v-else @click="onLoadMore">加载更多</a-button>
      </div>
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <div slot="description">
            <div
              style="float: left"
              :class="{ 'description-style': !item.isLoadingDecription }"
            >
              <a-tag v-for="(item, index) in item.draftCompany.split(',')" :key="index">{{ item }}</a-tag>
            </div>
            <a @click="showItem(item)" style="color: lightgrey; float: left">
              <div v-if="!item.isLoadingDecription">
                <a-icon type="down" />展开
              </div>
              <div v-else><a-icon type="up" />收起</div>
            </a>
          </div>
          <a-description slot="title">
            <a-description-item label :span="3">
              <strong>{{ item.name }}</strong>
            </a-description-item>
            <a-description-item label="标准类型">{{
              item.type
            }}</a-description-item>
            <a-description-item label="公开日期">{{
              item.publishDate
            }}</a-description-item>
          </a-description>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
import {
  Affix,
  BackTop,
  Button,
  Descriptions,
  Icon,
  List,
  Spin,
  Tag,
} from "ant-design-vue";

export default {
  components: {
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    ASpin: Spin,
    AIcon: Icon,
    AButton: Button,
    ADescription: Descriptions,
    ADescriptionItem: Descriptions.Item,
    ABackTop: BackTop,
    AAffix: Affix,
    ATag: Tag,
  },
  props: {
    fetchData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      lastPage: false,
      page: 0,
      top: 10,
      total: 0,
      isLoadingDecription: true,
      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      data: [],
    };
  },
  mounted() {
    this.companyId = this.$route.params.companyId;
    this.getData((res) => {
      this.loading = false;
      this.data = res;
    });
  },
  methods: {
    showItem(item) {
      if (item.isLoadingDecription == undefined) {
        this.$set(item, "isLoadingDecription", true);
      } else {
        item.isLoadingDecription = !item.isLoadingDecription;
      }
    },
    getData(callback) {
      if (!this.lastPage) {
        this.fetchData(this.companyId, this.page).then((res) => {
          this.lastPage = res.last;
          this.total = res.totalElements;
          this.page++;
          callback(res.content);
        });
      } else {
        setTimeout(() => {
          this.loadingMore = false;
          this.$message.info('没有数据了')
        }, 2000);
      }
    },
    onLoadMore() {
      this.loadingMore = true;
      this.getData((res) => {
        this.data = this.data.concat(res);
        this.loadingMore = false;
        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.demo-loadmore-list {
  min-height: 350px;
  .description-style {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 10em;
  }
}
</style>
